import React from 'react';
import {
    Typography,
    Grid,
    Paper,
    Card,
    CardContent,
    Container,
    Divider,
    Box,
    CardMedia
} from '@mui/material';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { makeStyles } from '@mui/styles';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import Team from './Team';
import Scroller from '../../components/Scroller';
import logo from '../../images/logo.svg';
import Header from '../../components/Header/index';
import Footer from '../../components/Footer';
import { content, paragraph, Reach_out } from '../../components/datas/AboutUs';

const useStyles = makeStyles({
    map: {
        textDecoration: 'none',
        color: '#4F5B60',
        '&:hover': {
            textDecoration: 'none',
            color: '#A6B50C'
        }
    },
    location: {
        ml: '-5px !important',
        fontFamily: 'Poppins !important',
        color: '#4F5B60',
        '&:hover': {
            color: '#A6B50C'
        }
    }
});

const AboutUs = () => {
    const classes = useStyles();
    const data = useStaticQuery(graphql`
        query aboutData {
            allAboutJson {
                edges {
                    node {
                        heading
                        subtitle
                        alt
                        images {
                            childImageSharp {
                                gatsbyImageData(width: 165, height: 125)
                            }
                        }
                    }
                }
            }
        }
    `);

    const AboutData = (data) => {
        const aboutArray = [];

        data.allAboutJson.edges.map((item, index) => {
            const image = getImage(item.node.images);

            aboutArray.push(
                <Grid key={index} item xs={12} sm={6} md={3} classes="about_grid">
                    <Card variant="about_card">
                        <CardMedia
                            sx={{
                                textAlign: 'center',
                                margin: '0',
                                pt: '1.2rem',
                                pl: '1.2rem',
                                pr: '1.2rem'
                            }}
                        >
                            <GatsbyImage image={image} alt="item.node.images" />
                        </CardMedia>
                        <Grid item xs={12} sm={12} md={12} sx={{ pt: '0.5rem' }}>
                            <CardContent sx={{ textAlign: 'center' }}>
                                <Typography variant="subTitle3">{item.node.heading}</Typography>

                                <Grid item xs={12} sm={12} md={12} sx={{ pt: '0.5rem' }}>
                                    <Typography variant="bodyText9">
                                        {item.node.subtitle}
                                    </Typography>
                                </Grid>
                            </CardContent>
                        </Grid>
                    </Card>
                </Grid>
            );
        });
        return aboutArray;
    };

    return (
        <div>
            <Header />
            <Paper
                variant="imageBanner"
                id="back-to-top-anchor"
                sx={{
                    position: 'relative'
                }}
            >
                <Grid sx={{ display: { xs: 'flex', md: 'flex', sm: 'flex' } }}>
                    <Box>
                        <StaticImage
                            src="../../images/about_banner.png"
                            alt="mask"
                            placeholder="none"
                        />
                    </Box>
                </Grid>
            </Paper>

            <Grid container spacing={2} sx={{ backgroundColor: '#fff', pt: '1rem', pb: '1rem' }}>
                <Grid
                    item
                    xs={12}
                    classes="about_globe"
                    sx={{ display: { xs: 'none', md: 'flex', sm: 'flex' } }}
                >
                    <Card variant="about_cards">
                        <img
                            src={logo}
                            alt="Group"
                            style={{
                                width: '125px',
                                height: '125px'
                            }}
                        />
                    </Card>
                </Grid>
                <Grid
                    item
                    xs={12}
                    classes="about_globes"
                    sx={{ display: { xs: 'flex', md: 'none', sm: 'none' } }}
                >
                    <Card variant="res_about_cards">
                        <img
                            src={logo}
                            alt="Group"
                            style={{
                                width: '80px',
                                height: '50px'
                            }}
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h2">
                        <span style={{ color: '#BECE20', letterSpacing: '2px' }}>
                            <b>NavaDhiti</b>{' '}
                        </span>{' '}
                        : A Conscious Start-up
                    </Typography>
                </Grid>

                <Grid item xs={1} />
                <Grid
                    item
                    xs={10}
                    md={10}
                    sm={10}
                    lg={10}
                    sx={{ pt: '1rem', pb: '1rem', textAlign: 'justify' }}
                >
                    {paragraph.map((item) => (
                        <Typography variant="bodyText2">
                            <span>
                                <b> {item.heading}</b>{' '}
                            </span>{' '}
                            {item.paragraph1}
                            <br />
                            <br />
                            {item.paragraph2}
                            <br />
                            <br />
                            {item.paragraph3}
                            <span>
                                <b>{item.subheading}</b>
                            </span>
                        </Typography>
                    ))}
                </Grid>
                <Grid item xs={2} />
            </Grid>
            <Paper variant="about_banner">
                <StaticImage
                    src="../../images/mask.svg"
                    style={{
                        position: 'absolute',
                        right: '0px',
                        maxHeight: '200px',
                        maxWidth: '200px'
                    }}
                    placeholder="none"
                />
                <Grid container sx={{ p: 8 }}>
                    <Typography variant="h2" sx={{ mt: '-3rem' }}>
                        What We{' '}
                        <span style={{ color: '#BECE20' }}>
                            <b>Believe</b>
                        </span>
                    </Typography>
                </Grid>
            </Paper>

            <Container>
                <Grid
                    container
                    spacing={3}
                    sx={{
                        mt: '-2.8rem',
                        pb: '1rem'
                    }}
                >
                    {AboutData(data)}
                </Grid>
            </Container>
            <Grid md={12} sx={{ pt: '3.5rem', pb: '1.2rem' }}>
                <Typography variant="h2">
                    What We{' '}
                    <span style={{ color: '#BECE20' }}>
                        <b>Have</b>
                    </span>
                </Typography>

                <Container>
                    <Grid id="top-row" container>
                        {content.map((item, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                justifyContent="space-around"
                                textAlign="center"
                                sx={{ pt: '1rem', pb: '1rem' }}
                            >
                                <Typography variant="subTitle5">{item.title}</Typography>
                                <Grid
                                    item
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    justifyContent="center"
                                    textAlign="center"
                                    sx={{ pt: '0.5rem', pb: '1rem' }}
                                >
                                    <Typography variant="h10"> {item.description}</Typography>
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </Grid>
            <Grid md={12} sx={{ pt: '1.2rem', pb: '1.2rem' }}>
                <Paper variant="team_banner">
                    <StaticImage
                        src="../../images/mask.svg"
                        style={{
                            position: 'absolute',
                            right: '0px',
                            maxHeight: '200px',
                            maxWidth: '200px'
                        }}
                        placeholder="none"
                    />
                    <Typography variant="h2" sx={{ mt: '20px' }}>
                        Who We{' '}
                        <span style={{ color: '#BECE20' }}>
                            <b>Are</b>
                        </span>
                    </Typography>
                </Paper>
            </Grid>
            <Grid
                container
                spacing={2}
                sx={{
                    mt: '-55px',
                    padding: '0px 2.5rem !important',
                    display: 'flex',
                    position: 'relative'
                }}
            >
                <StaticImage
                    src="../../images/aboutus.png"
                    style={{
                        float: 'right',
                        position: 'absolute',
                        marginTop: '250px',
                        right: '0px'
                    }}
                    placeholder="none"
                />
                <Container>
                    <Team />
                </Container>
            </Grid>

            <Container>
                <Grid md={12} sx={{ pt: '1rem', pb: '2rem' }}>
                    <Divider sx={{ backgroundColor: '#BECE20' }} />
                </Grid>
            </Container>

            <Container>
                <Grid container>
                    <Grid item xs={12} md={4} sm={4}></Grid>

                    {Reach_out.map((item, key) => (
                        <></>
                    ))}
                </Grid>
            </Container>

            <Grid item sx={{ position: 'relative' }}>
                <StaticImage
                    src="../../images/Mask Group 20.svg"
                    height="280"
                    style={{
                        position: 'absolute',

                        bottom: '-180px'
                    }}
                    placeholder="none"
                />
            </Grid>

            <Container sx={{ position: 'relative' }}>
                <Grid container spacing={2} paddingBottom="50px">
                    <Grid item xs={12} md={4} sm={6} fontFamily="Raleway">
                        <Typography variant="reachdata">Reach-Out to us…</Typography>
                        <br />

                        {/* <Typography variant="subTitle12"></Typography>
                            <br /> */}
                        <a
                            href="mailto:connect@navadhiti.com"
                            target="blank"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography variant="bodyText12">
                                {' '}
                                <EmailIcon
                                    sx={{
                                        fontSize: '18px',
                                        marginBottom: '-4px',
                                        marginRight: '3px',
                                        fontFamily: 'Raleway !important',
                                    }}
                                />
                                connect@navadhiti.com
                            </Typography>
                        </a>
                    </Grid>

                    {Reach_out.map((item, index) => (
                        <Grid item key={index} xs={12} md={3} sm={6} sx={{ mt: '10px' }}>
                            <a href={item.map} target="blank" className={classes.map}>
                                <Typography
                                    className={classes.location}
                                    sx={{
                                        ml: '-5px',
                                        fontWeight: 600
                                    }}
                                >
                                    <LocationOnIcon
                                        sx={{
                                            fontSize: '20px',
                                            marginBottom: '-4.3px'
                                        }}
                                    />{' '}
                                    {item.heading}
                                </Typography>
                            </a>
                            <Typography variant="h9">{item.address}</Typography>
                            <br />
                            <Typography variant="h9">{item.address1}</Typography>
                            <br />
                            <Typography variant="h9">{item.address2}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Scroller />
            <Footer sx={{ position: 'static' }} />
        </div>
    );
};

export default AboutUs;
