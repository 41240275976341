// eslint-disable-next-line import/prefer-default-export

const content = [
    {
        title: 'Appetence',
        description:
            'Inherent deep desire to do well, fire in the belly to excel and prove oneself.'
    },
    {
        title: 'Aptitude',
        description:
            'A natural ability which is suitable for the Technology & SW Development Profile.'
    },
    {
        title: 'Attitude',
        description: 'The outlook, behaviour and perspectives towards delivering positive value.'
    },
    {
        title: 'Alignment',
        description:
            'Excited by the vision and aim of the company, is aligned to the guiding principles.'
    }
];
const paragraph = [
    {
        heading: 'Founded in the year 2019,',
        paragraph1:
            'NavaDhiti is a full stack IT services company delivering scalable software technology solutions to our esteemed customers. During this journey, we aspire to create careers to the deserving youth and also support various social impact organizations with their technological needs.',
             paragraph2:
            'We hope to inspire a culture of self-governed professionals with high ethical values, focussed on value impact for our customers & stake holders.',
            paragraph3:
            'With offices at Bangalore & Mettupalayam, we are currently a 75+ member team comprising of Architects, Full Stack Developers, Business Analysts, Tech Leads,  Data Engineers & QA Engineers.',
        subheading:
            ''
    }
];

const cardData = [
    {
        heading: 'Social Impact',
        imgurl: 'src/images/Platform & Product Engineering.svg',
        imgurl_alt: 'data',
        paragraph:
            'We believe that as a partner, the nucleus of all our technology solutions helps drive social impact.'
    },
    {
        heading: 'Build Credibility',
        imgurl: 'src/images/Platform & Product Engineering.svg',
        imgurl_alt: 'data',
        paragraph:
            'We believe that our service delivery & our work ethics speaks for our ability to deliver consistent value.'
    },
    {
        heading: 'Social Impact',
        imgurl: 'src/images/Platform & Product Engineering.svg',
        imgurl_alt: 'data',
        paragraph:
            'We believe that our focus in every engagement is to see how best they lead towards long term associations.'
    },
    {
        heading: 'Social Impact',
        imgurl: '/images/Platform & Product Engineering.svg',
        paragraph:
            'We believe that at every touch point across all our stakeholder interactions, its the experience that matters.'
    }
];

const Reach_out = [
    {
        heading: 'Bengaluru :',
        address:
            '# 1432, 6th Cross Rd, 10th Main,  ',
            address1:`(Leela Palace Road), Kodihalli,`,
            address2:`Bengaluru - 560008, Karnataka.`,
        map: 'https://goo.gl/maps/VTray9KxGowb2jZP9'
    },
    {
        heading: 'Mettupalayam : ',
        address: '#5, Ponnamal Garden,',
        address1: '4th Street, Thottipalyam Road,',
        address2: 'Karamadai - 641104, Tamil Nadu.',
        map: 'https://goo.gl/maps/Zav9PRnqkVP5Rppj7'
    }
];

module.exports = {
    content,
    paragraph,
    cardData,

    Reach_out
};
